/* DEFAULT VARIABLES */
body {
  --block-text-color: #222;
  --block-background-color: #fff;
  --block-accent-color: #00ae86;
  --block-shadow-color: #444;
}

/* BASIC BLOCK STYLES */
.block {
  display: block;
  color: var(--block-text-color);
  border: 3px solid var(--block-text-color);
  border-radius: 3px;
  padding: 4px 8px;
  background: var(--block-background-color);
  font-weight: bold;
  cursor: pointer;
  box-sizing: border-box;

  position: relative;
  top: -2px;
  left: -2px;
  transition: transform 0.2s;
  margin: 8px 6px 10px 6px;
  z-index: 1;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
}

.block.wrapper,
.block.wrapper.inline {
  display: inline-block;
  padding: 0;
}

.block.wrapper > * {
  margin: 0;
}

/* INTERACTIVE BLOCK STYLES */
.block::before {
  content: "";
  background: var(--block-background-color);
  border: 3px solid var(--block-text-color);
  border-radius: 3px;
  box-sizing: border-box;
  position: absolute;
  top: -3px;
  left: -3px;
  height: calc(100% + 6px);
  width: calc(100% + 6px);
  z-index: -1;
}

.block:hover,
.block:focus {
  transform: translate(2px, 2px);
}

.block::after {
  content: "";
  display: block;
  box-sizing: border-box;
  background: var(--block-shadow-color);
  border: 3px solid var(--block-text-color);
  border-radius: 3px;
  height: calc(100% + 6px);
  width: calc(100% + 6px);
  position: absolute;
  top: 3px;
  left: 3px;
  right: 0;
  z-index: -2;
  transition: transform 0.2s;
}

.block:hover::after,
.block:focus::after {
  transform: translate(-2px, -3px);
}

.block:active {
  color: var(--block-text-color);
  transform: translate(3px, 3px);
}

.block:active::after {
  transform: translate(-4px, -4px);
}

.block:focus {
  outline: none;
}

.block.fixed {
  cursor: initial !important;
}

/* FIXED STYLES */
.block.fixed:hover,
.block.fixed:hover::before,
.block.fixed:hover::after,
.block.fixed:active,
.block.fixed:active::before,
.block.fixed:active::after,
.block.fixed:focus,
.block.fixed:focus::before,
.block.fixed:focus::after {
  transform: none !important;
}

/* ACCENT STYLES */
.block.accent {
  color: var(--block-background-color);
  background: var(--block-accent-color);
}

.block.accent::before {
  background: var(--block-accent-color);
}

/* INLINE STYLES */
.block.inline {
  display: inline-block;
  font-size: 0.75em;
  padding: 0 6px;
  margin: 3px 2px 1px 4px;
}
.block.inline::after {
  top: -1px;
  left: -1px;
}
.block.inline:hover,
.block.inline:focus {
  transform: translate(1px, 1px);
}
.block.inline:hover::after,
.block.inline:focus::after {
  transform: translate(-1px, -1px);
}
.block.inline:active {
  transform: translate(2px, 2px);
}

/* ROUND STYLES */
.block.round,
.block.round::before,
.block.round::after {
  border-radius: 30px;
}

.block.round::after {
  left: 1px;
}

